import React from "react"
import styled from 'styled-components'
import Description from '../../components/Description'
import Facts from '../../components/Facts'

const IntroWrapper = styled.section`
  padding:0 1.5em;
  margin-bottom:1.5em;
  >*{
    &:not(:last-child){
      margin-bottom:1.5em;
    }
  }
  @media (min-width: 562px) {
    padding:0 3em;
    margin-bottom:8.5em;
  }
  @media (min-width: 1024px) {
    display:flex;
    align-items:flex-end;
    >*{
      &:first-child{
        width:${(537/1343)*100}%;
      }
      &:last-child{
        width:${(652/1343)*100}%;
        margin-left:3em;
      }
    }
    ul{
      margin-bottom:0 !important;
    }
  }
`

const Intro = (props) => {
  const {
    facts,
    description
  } = props

  if(!facts || !description){
    return false
  }

  return(
    <IntroWrapper>
      <Facts data={facts} />
      <Description richText={description} ctaText={false} />
    </IntroWrapper>
  )
}

export default Intro