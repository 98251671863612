import React, {useEffect, useState, useRef} from 'react'
import {graphql} from 'gatsby'
import Layout from "../components/layout"
import Hero from '../components/Hero'
import Slices from '../components/Slices'
import get from 'lodash/get'
import Intro from '../components/Intro'
import NextCase from '../components/NextCase'
import InViewBatch from '../components/InViewBatch'
import SEO from "../components/seo"
const Case = (props) => {
  const edges = get(props.data, 'prismic.allCases.edges', [])
  const data = get(edges, '0.node')

  if (!data) {
    return null
  }

  const title_line_1 = get(data,'title_line_1[0].text'),
        title_line_2 = get(data,'title_line_2[0].text'),
        thumbnail_file = get(data,'thumbnail_file'),
        description = get(data,'description'),
        facts = get(data,'facts'),
        content = get(data,'content'),
        nextCase = get(data,'next_case')

  return(
    <Layout>
      <SEO
        title={title_line_1 + ' ' + title_line_2}
        description={get(description,'[0].text')}
        image={get(thumbnail_file,'url')  + '&w=1280&h=630'}
      />
      <InViewBatch >
        <Hero
          titleLineOne={title_line_1}
          titleLineTwo={title_line_2}
          img={thumbnail_file}
        />
        <Intro
          description={description}
          facts={facts}
          />
        <Slices data={content} />
      </InViewBatch>
      {nextCase &&
        <NextCase {...nextCase} />
      }
    </Layout>
  )
}
export const query = graphql`
  query Case($uid: String) {
    prismic {
      allCases(uid: $uid) {
        edges {
          node {
            title_line_2
            title_line_1
            thumbnail_file
            facts {
              fact
              fact_title
            }
            description
            next_case {
              ... on PRISMIC_Case {
                title_line_1
                title_line_2
                _meta {
                  uid
                  type
                }
                thumbnail_file
              }
            }
            content {
              ... on PRISMIC_CaseContentList {
                type
                label
                fields {
                  link {
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                    ... on PRISMIC_Case {
                      title_line_1
                      title_line_2
                      _meta {
                        type
                        uid
                      }
                    }
                  }
                  name
                }
                primary {
                  title
                }
              }
              ... on PRISMIC_CaseContentVideo {
                type
                label
                primary {
                  thumbnail_file
                  video_title
                  video_file {
                    ... on PRISMIC__FileLink {
                      _linkType
                      url
                    }
                  }
                  video_title
                }
              }
              ... on PRISMIC_CaseContentImage_row {
                type
                label
                fields {
                  image_file
                  size
                }
              }
              ... on PRISMIC_CaseContentText {
                type
                label
                primary {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Case
Case.query = query