import React, {useState,useRef, useEffect, useContext} from 'react'
import styled from 'styled-components'
import LazyImage from '../LazyImage'
import TwoLinesTitle from '../TwoLinesTitle'
import {linkResolver} from '../../utils/linkResolver'
import TransitionLink from 'gatsby-plugin-transition-link'
import gsap from 'gsap'
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import {cover} from 'polished'
import {
  GlobalDispatchContext,
} from '../../context/GlobalContextProvider'

gsap.registerPlugin(ScrollToPlugin);

const killTimeline = (timeline) => {
  const targets = timeline.getChildren();

  timeline.kill();

  for(let i = 0; i < targets.length; i++) {
    if(targets[i].targets().length) {
       gsap.set(targets[i].targets(), {clearProps:"all"});
    }
  }
};

const NextCaseWrapper = styled.header`
    height:50vh;
    position:relative;
    margin-top:${5.5 + 3}em;
    @media (min-width: 1280px) {
      margin-top:${10 + 8.5 + 1.5}em;
    }
  .image-wrapper{
    background:${props => props.theme.colors.darkest};
    ${cover()};
    overflow:hidden;
  }
  .title-wrapper{
    position:absolute;
    top:0;
    left:1.5em;
    pointer-events:none;
    @media (min-width: 562px) {
      left:3em;
    }
    transform:translate3d(0,-76%,0);
    z-index:1;
    a{
      background:green;
      display:block;
    }
    h3{
      position:absolute;
      font-style:italic;
      text-transform:uppercase;
      color:${props => props.theme.colors.primary };
      top:-1.5em;
      left:0;
      transform:translate3d(0,-100%,0);
    }
  }
`

const NextCase = (props) => {
  const {
    title_line_1,
    title_line_2,
    thumbnail_file,
    _meta
  } = props,
  [isHover, setIsHover] = useState(false),
  nextCaseWrapperRef = useRef(),
  tl = useRef(),
  dispatch = useContext(GlobalDispatchContext)


  useEffect(()=>{
    tl.current = gsap.timeline({
      paused: true
    })
    .to(nextCaseWrapperRef.current.querySelectorAll('.img'),{
      scale:1.05,
      duration:1,
      ease: "power2.inOut",
      autoAlpha:.66,
      overwrite:'all'
    },'intro')
  },[])

  useEffect(()=>{
    isHover === true ? tl.current.play() : tl.current.reverse()
    dispatch({
      type: 'SET_CURSOR',
      cursor:{
        visible:isHover,
        text: 'View Case'
      },
    })

  },[isHover, dispatch])

  return (
    <NextCaseWrapper
      ref={nextCaseWrapperRef}
      onMouseMove={()=>setIsHover(true)}
      onMouseLeave={()=>setIsHover(false)}
      className="next-case">
      <TransitionLink
        preventScrollJump
        to={linkResolver(_meta)}
        exit={{
          trigger: ({ node, e, exit, entry }) => {
            const nextCase = e.target.closest(".next-case")
            const animatables = node.querySelectorAll(".animatable")
            const watermark = node.querySelectorAll(".watermark")
            const tl = gsap.timeline({
              onStart:()=>{
                setIsHover(false)
              }
            })
            .set(nextCase,{
              marginBottom:'50vh',
              pointerEvents:'none'
            })
            .to(window,{
              scrollTo:nextCase.offsetTop,
              duration:1,
              ease: "power2.inOut",
              overwrite:'all',
            },'same')
            .set(nextCase,{
              position:'fixed',
              top:0,
              left:0,
              margin:0,
              width:'100%',
            })
            .to(nextCase.querySelectorAll('h3'),{
              autoAlpha:0,
              yPercent:-100,
              duration:1,
              ease: "power2.inOut",
              overwrite:'all',
            },'same')
            .to(animatables,{
              autoAlpha:0,
              duration:1,
              ease: "power2.inOut",
              overwrite:'all',
            },'same')
            .to(watermark,{
              autoAlpha:0,
              duration:1,
              ease: "power2.inOut",
              overwrite:'all',
            },'same')
            .to(nextCase.querySelectorAll('.title-wrapper'),{
              top:'100%',
              yPercent:52,
              duration:1,
              ease: "power2.inOut",
              overwrite:'all',
            },'same')
          },
          length: 1
        }}
        entry={{
          delay:1,
          trigger: ({ node, e, exit, entry }) => {
            gsap.set(window,{
              scrollTo:0,
              overwrite:'all',
            })
            // gsap.set(node,{
            //   opacity:0,
            // })

            // setTimeout(()=>{
            //   gsap.set(node,{
            //     opacity:1,
            //     overwrite:'all'
            //   })
            //   gsap.set(window,{
            //     scrollTo:0,
            //     overwrite:'all',
            //   })
            // },1000)
          }
        }}
      >
        <div className="image-wrapper">
          <LazyImage {...thumbnail_file} loaded={true} />
        </div>
      <div className="title-wrapper">
        <h3>Next Case</h3>
        <TwoLinesTitle
          titleLineOne={title_line_1[0].text}
          titleLineTwo={title_line_2[0].text}
        />
      </div>
      </TransitionLink>
    </NextCaseWrapper>
  )
}

export default NextCase