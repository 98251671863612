import React from 'react'
import styled from 'styled-components'
import { RichText} from 'prismic-reactjs';
import get from 'lodash/get'
import Paragraph from '../Paragraph'
const FactsWrapper = styled.ul`
  text-transform:uppercase;
  font-style:italic;
  li{
    &:not(:last-child){
      margin-bottom:1.5rem;
    }
  }
  h3{
    color:${props => props.theme.colors.primary};
    margin-bottom:.25em;
  }
`

const Facts = (props) => {
  const {data} = props

  if (!data) {
    return null;
  }


  return(
    <FactsWrapper>
      {data.map((fact,i)=>{
        const factText = get(fact,'fact'),
        factTitle = get(fact,'fact_title[0].text')

        return(
          <li className="animatable">
          <h3>{factTitle}</h3>
          <Paragraph>{RichText.render(factText)}</Paragraph>
          </li>
        )
      })}
    </FactsWrapper>
  )
}

export default Facts